import React from "react"
import {
  ActivityBox,
  DiscussBox,
  DefinitionBox,
  QuoteBox,
  ToSeeBox,
  WhoWasBox,
  CopyrightBox
} from "../../../components/boxes"

import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import bertha from "./bertha.jpg"
import bertha2 from "./bertha-2x.jpg"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import worksheets from "../worksheets.svg"
import sundialFrame from './sundial.jpg'
import { Video } from '../../../components/video'
import { css } from '@emotion/react'
import { withPrefix } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

const StAugustine = () => (
  <Layout title="Saint Augustine">
    <SmallSectionHeader
      color={theme.colors.purple.dark}
      image={headerImage}
      to="/a-walk-through-time"
    >
      A walk through time
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/a-walk-through-time', name: 'A walk through time' }, { name: 'Saint Augustine' }
    ]} />
    <Page>
      <h1>Saint Augustine</h1>

      <p>In 597 Saint Augustine came to Anglo-Saxon Canterbury. He was sent by Pope Gregory the Great to re-establish Christianity in England and was accompanied by around 40 monks. King Ethelbert of Kent welcomed Augustine and gave him land outside the city walls to establish a monastery as well as the land on which the current Cathedral stands. When he first arrived in Canterbury, Saint Augustine used the small church of St Martin (which is still in use today) in which to worship. Ethelbert was later baptized as a Christian by Augustine.</p>

      <hr />

      <h2>Augustine at Canterbury</h2>
      <p className="page-left">
        When Augustine arrived in Canterbury there were already Christians living in the West and North of Britain but many people living in the South of Britain, including Canterbury, worshipped different gods. The main Anglo-Saxon God was Woden.
      </p>
      <Figure
        caption="This 19th century stained glass image of Saint Augustine is taken from the Chapter House west window in Canterbury Cathedral"
        className="page-right row-2"
      >
        <StaticImage
          src="./augustine.jpg"
          alt="Saint Augustine depicted in stained glass"
        />
      </Figure>
      <p className="page-left">
        Britain was made up of many warring kingdoms. Augustine was sent to Anglo-Saxon Kent because King Ethelbert was very powerful and because he had married Bertha, a princess from an area that is now part of France, who was already a Christian. After King Ethelbert became a Christian many other Anglo-Saxons turned to Christianity and many new churches and monasteries were built.
      </p>

      <DiscussBox>
        Why do you think Augustine went to meet King Ethelbert when he first came to England?
      </DiscussBox>

      <p>
        The city of Canterbury has a long history. There was an iron age settlement and later a Roman town. The Saxons called the town Cantwaraburgh which means the stronghold of the people of Kent. By the year 998 there were probably around 5000 people living in Canterbury. Craftspeople produced fine objects for trade with England and wider Europe.
      </p>

      <hr />

      <h2>Evidence</h2>
      <p className="page-left row-2">
        Archaeological evidence (the physical remains of buildings and objects studied for what they tell us about the past) can tell us more about Saxon Canterbury. Augustine built a stone church on the site where the Cathedral is today. The remains of this church are underneath the current building. In 1938 a Saxon sundial was excavated (dug up) in the quadrangle of the Cathedral’s Cloister. We think the sundial was owned by a monk because the times of daily offices (the prayers or psalms, said or chanted, at specific times of the day by the monks) are marked on it. A Saxon brooch in the shape of a cross was discovered during excavations of St George’s Street, Canterbury. The design of the brooch tells us the people who made it were Christian.
      </p>

      <Figure
        caption="This film clip explains the purpose of the Saxon Sundial and how it works"
        className="page-right"
      >
        <Video
          placeholder={sundialFrame}
          css={css`
            max-height: 90vh;
          `}
          sources={[
            { type: 'mp4', src: "sundial.mp4" },
            { type: 'webm', src: "sundial.webm" }
          ]}
        />
      </Figure>

      <DefinitionBox title="Archaeology" className="page-right">
        is the study of history and pre-history through the physical remains of
        buildings and objects.
      </DefinitionBox>

      <WhoWasBox
        name="Queen Bertha"
        dates="b. early 560s - d. after 604"
        image={{
          src: bertha,
          srcSet: `${bertha} 224w, ${bertha2} 488w`,
          alt: "Queen Bertha",
        }}
      >
        <p>Queen Bertha was a princess from an area that is now part of France, the daughter of the King of Paris. She came to England after she married King Ethelbert. She was a Christian and brought a bishop named Liudhard with her to England. Our main source of information about Bertha is the monk historian Bede, who wrote a book called ‘The Ecclesiastical History of the English People’ 150 years after Bertha’s death. He said that part of the wedding agreement between Bertha and Ethelbert was that Bertha should be allowed to practice her faith and so ‘the fame of the Christian religion had already reached [Ethelbert]’. A letter sent from Pope Gregory to Bertha in 601 also survives. In this letter, the Pope suggests Bertha ought to have done more to convert her husband to Christianity before Augustine’s arrival in Britain but he also thanked her for the warm welcome Augustine received.</p>
      </WhoWasBox>

      <QuoteBox cite="Letter from the Pope to Queen Bertha.">
        You ought, already long ago, by your truly Christian wisdom to have inclined the mind of our glorious son, your husband, to follow the faith which you hold, for the sake both of his own and his people’s salvation.
      </QuoteBox>

      <DiscussBox>
      What role did Queen Bertha play in the spread of Christianity throughout England?
      </DiscussBox>

      <ToSeeBox
        items={[
          {
            location: "South West entrance door",
            items: [
              {
                title: "Canterbury Cross",
                content:
                  "This is a copy in bronze of the Saxon brooch which was found in St George’s Street, Canterbury. The brooch became a symbol for the Church of England. In 1935 copies of this bronze were sent to Anglican cathedrals throughout the world to represent their fellowship with Canterbury.",
              },
              {
                title: "Statues of Augustine, Ethelbert and Bertha",
                content:
                  "These statues were installed in 1870. The statue of King Ethelbert is holding a model of the stone church built on the Cathedral site.",
              },
            ],
          },
          {
            location: "Water Tower",
            items: [
              {
                title: "The exhibition, Cathedral Life",
                content:
                  "Part of the exhibition in this part of the Cathedral explores monastic life; you can see the Anglo-Saxon Sundial as well as a medieval stylus used to create manuscripts at Canterbury Cathedral.",
              },
            ],
          },
          {
            location: "Crypt",
            items: [
              {
                title: "Reculver Columns",
                content:
                  "These two large columns were brought to the Cathedral for safe-keeping. They originally stood in the Anglo-Saxon church in Reculver on the coast, which was built in 670.",
              },
            ],
          },
          {
            location: "Chapter House",
            items: [
              {
                title: "East Window",
                content:
                  "This window was given to the Cathedral in 1896 to commemorate 1300 years since Augustine came to Kent. On the top row you can see Augustine preaching to King Ethelbert and Queen Bertha.",
              },
            ],
          },
        ]}
      />

      <h2>Activities</h2>
      <ActivityBox
        title="A Walk Through Time activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/AWalkThroughTime.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘A walk through time’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "The Vikings", to: "/a-walk-through-time/vikings" },
          { name: "Archbishop Lanfranc", to: "/a-walk-through-time/lanfranc" },
          { name: "Thomas Becket", to: "/a-walk-through-time/becket" },
        ]}
      />

      <hr />

      <CopyrightBox />
    </Page>
  </Layout>
)

export default StAugustine